<template>
  <div class="class courseGlobal">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "classroom",
  //components: { ContractsList, Contracts, PaymentRecords, LgaClasses },
  methods: {},
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.courseGlobal {
  //background: #fff;
  //padding: 0 40px 80px 40px;
}
</style>